<template>
  <div>
    <v-app-bar app>
      <div class="d-flex align-center">
        <v-img
          class="shrink mr-2"
          contain
          src="@/assets/DoubleRiver.png"
          transition="scale-transition"
          width="40"
        />
      </div>

      <div id="nav">
        <v-btn
          text
          v-for="route in nav_routes"
          :key="route.name"
          @click="() => navigate(route.link)"
        >
          {{ route.name }}
        </v-btn>
      </div>

      <v-spacer></v-spacer>

      <!-- Tools -->
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <div id="menu-more" v-bind="attrs" v-on="on">
            <v-btn text>Tools<v-icon>mdi-chevron-down</v-icon></v-btn>
          </div>
        </template>
        <v-list>
          <v-list-item-group>
            <v-list-item
              v-for="(item, i) in menu_options"
              :key="i"
              @click="handle_menu_option(item)"
            >
              <v-list-item-icon>
                <v-icon right>mdi-{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>

      <!-- Quick Links -->
      <v-menu offset-y max-width="160px">
        <template v-slot:activator="{ on, attrs }">
          <div id="menu-more" v-bind="attrs" v-on="on">
            <v-btn text>Quick Links<v-icon>mdi-chevron-down</v-icon></v-btn>
          </div>
        </template>
        <v-list>
          <v-list-item-group>
            <v-list-item v-for="(item, i) in quick_links" :key="i">
              <a
                :href="item.link"
                target="_"
                style="
                  text-decoration: none;
                  color: black;
                  text-align: left;
                  width: 100%;
                "
              >
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </a>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>

      <!-- User Profile Menu -->
      <v-menu offset-y max-width="200px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="user"
            text
            id="menu-profile"
            style="padding-top: 24px; padding-bottom: 24px"
            v-bind="attrs"
            v-on="on"
          >
            <b>{{ user.name }}</b>
            <div style="margin-left: 12px" />
            <!-- (Just a spacer) -->
            <img
              :src="user.picture"
              width="40"
              height="40"
              referrerpolicy="no-referrer"
            />
          </v-btn>
          <span v-else>Loading profile data... </span>
        </template>
        <div style="background-color: white">
          <v-btn text style="width: 100%" @click="$api.auth.logout()"
            ><v-icon left>mdi-logout</v-icon>Sign Out</v-btn
          >
        </div>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "DRNav",
  data() {
    return {
      user: this.$api.user,
      nav_routes: [
        { name: "Home", link: "/" },
        { name: "Backtesting", link: "/backtester" },
        { name: "Alpha Signals", link: "/alpha-signals" },
        { name: "Rebalance", link: "/rebalance" },
        { name: "Attribution", link: "/attribution" },
        //{ name: "Paper Trading", link: "/portfolios?filter=paper" },
        //{ name: "Live Trading", link: "/portfolios?filter=live" },
      ],
      menu_options: [
        // The way it's set up now, you can technically
        // have a link AND a callback and both will be used.
        {
          name: "Report Generation Status",
          icon: "finance",
          link: "/report-status",
        },
        {
          name: "Code Documentation",
          icon: "file-document-multiple",
          link: "/docs",
        },
      ],
      quick_links: [
        {
          name: "Confluence",
          link: "https://double-river.atlassian.net/wiki/spaces/DOUBLERIVE",
        },
        {
          name: "Jira",
          link: "https://double-river.atlassian.net/jira/projects",
        },
        { name: "BitBucket", link: "https://bitbucket.org/doubleriver/" },
      ],
    };
  },
  created() {
    // Add method as observer so when a new sign-in occurs, we can show the name/picture.
    this.$api.auth.addAuthObserver((user) => (this.user = user));
  },
  methods: {
    handle_menu_option(option) {
      if (option.callback) option.callback();
      if (option.link) this.navigate(option.link);
    },
    navigate(link) {
      // Avoid redundant navigation.
      if (this.$router.currentRoute.fullPath != link) {
        this.$router.push(link, null, null);
        // Use this custom event to let an observer know the query string
        // was updated, because by default they would not know
        // (Currently only used in /portfolios)
        if (this.$router.queryUpdated) this.$router.queryUpdated();
      } else {
        console.log("Caught redundant navigation to", link);
      }
    },
  },
};
</script>

<style scoped>
#menu-profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-radius: 6px;
}
#menu-profile:hover {
  background-color: #eaeaea;
}

#menu-profile p {
  margin: 0;
  padding: 0;
}
</style>
