class AuthService {
  CLIENT_ID =
    "826563404267-lol5keh64gh02jhm1h4ka64nas102efk.apps.googleusercontent.com";
  apiClient;
  gClient;

  _authenticatedUser;

  get authenticatedUser() {
    return this._authenticatedUser;
  }

  set authenticatedUser(user) {
    this._authenticatedUser = user;
    this.notifyObservers(user);
  }

  authObservers = [];

  notifyObservers(data) {
    this.authObservers.forEach((ob) => {
      ob(data);
    });
  }

  addAuthObserver(func) {
    this.authObservers.push(func);
  }

  constructor(apiClient) {
    // Init API Client
    this.apiClient = apiClient;
    // Check with the server to see if we are already authenticated
    this.updateSession();
  }

  async updateSession() {
    let resp = await this.apiClient.authGetUser();
    if (resp.status == 200) this.authenticatedUser = resp.data;
    else console.log("Not already authenticated!");
  }

  _initGClient(callback) {
    // Prepare Google Sign-In SDK (only used for new sign-in)
    let authScript = document.createElement("script");
    authScript.setAttribute("src", "https://accounts.google.com/gsi/client");
    authScript.setAttribute("type", "application/javascript");
    document.head.appendChild(authScript);
    // Initialize Google Auth client once the script has been loaded.
    authScript.onload = () => {
      /* eslint-disable */
      if (typeof google !== "undefined") {
          this.gClient = google.accounts.oauth2.initCodeClient({
            // eslint-disable-line
            client_id: this.CLIENT_ID,
            scope:
              "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
          });
      }
      /* eslint-enable */
      callback();
    };
  }

  login() {
    let doSignIn = () => {
      this.gClient.callback = async (codeResp) => {
        this.authenticatedUser = (
          await this.apiClient.authSignIn(codeResp.code)
        ).data;
      };
      this.gClient.requestCode();
    };

    if (!this.gClient) this._initGClient(doSignIn);
    else doSignIn();
  }

  logout() {
    this.apiClient.authSignOut();
    this.authenticatedUser = null;
  }
}

export default AuthService;

// async _getInitialJWT(authCode) {
//   await this.apiClient.authGetToken(authCode);
//   this.notifyObservers(this.signInStatusObservers, true);
// }

// async _refreshToken() {
//   let resp = await this.apiClient.authRefreshToken();
//   return resp.status == 200;
// }

// _parseCookie() {
//   return document.cookie
//     .split(';')
//     .map(v => v.split('='))
//     .reduce((acc, v) => {
//       if (v[0] && v[1])
//         acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
//       return acc;
//     }, {});
// }

// _deleteCookie(name) {
//   document.cookie = name+'=; Max-Age=-99999999;';
// }

// _decodeJwt(jwt) {
//   let base64 = jwt.split('.')[1].replace(/-/g, '+').replace(/_/g, '/');
//   let payload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
//       return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
//   }).join(''));
//   return JSON.parse(payload);
// }

// _updateUserData(jwt) {
//   if (!jwt) {
//     jwt = this._parseCookie()['Google-Auth'];
//   }
//   this.userData = this._decodeJwt(jwt);
//   this.notifyObservers(this.userDataObservers, this.userData);
// }

// async getIdToken(force_expire) {
//   let cookie = this._parseCookie();
//   let jwt = cookie['Google-Auth'];
//   if (jwt) {
//     // Check exp, refresh if necessary
//     this._updateUserData(jwt);
//     let expired = force_expire || new Date(this.userData.exp) >= new Date();
//     if (expired) {
//       let refreshSuccess = await this._refreshToken();
//       if (refreshSuccess) return await this.getIdToken();
//       // Recursive call until either a valid JWT is obtained,
//       // refresh fails, or no JWT can be found in the cookies.
//     } else return jwt;
//   }
//   else return null;
// }

// async isLoggedIn() {
//   // return !!(await this.getIdToken());
// }

// async getUser() {
//   return this.apiClient.authGetUser();
// }
