<template>
  <v-container id="container">
    <h2>Live and Paper Trading Status</h2>
    <br />
    <v-row>
      <v-col
        cols="6"
        v-for="portfolio in portfolio_summaries"
        :key="portfolio.portfolio_id"
      >
        <v-card class="status-card">
          <router-link
            :to="
              '/portfolio/' +
              portfolio.trading_platform +
              '/' +
              portfolio.portfolio_id
            "
            ><h3>
              Portfolio {{ portfolio.portfolio_id }} ({{ portfolio.username }})
            </h3></router-link
          >

          <i>Active since {{ portfolio.start_date }}</i>
          <br />

          <v-chip
            class="chip"
            small
            pill
            v-if="portfolio.live == 0"
            color="green darken-1"
            text-color="white"
            >Paper</v-chip
          >
          <v-chip
            class="chip"
            small
            pill
            v-if="portfolio.live == 1"
            color="red darken-2"
            text-color="white"
            >Live</v-chip
          >
          <div>
            <br />
            <v-row>
              <v-col>
                <canvas
                  :id="'chart1-portfolio' + portfolio.portfolio_id"
                  height="100px"
                />
                <v-simple-table dense>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td class="text-left" />
                        <td class="text-left">$</td>
                        <td class="text-left">%</td>
                      </tr>
                      <tr>
                        <td class="text-left">Net Returns</td>
                        <td class="text-left">
                          {{
                            currency_format(
                              portfolio.returns.cumulative_net_return
                            )
                          }}
                        </td>
                        <td class="text-left">
                          {{
                            format_metric_value(
                              portfolio.returns["cumulative_net_return_%"]
                            )
                          }}%
                        </td>
                      </tr>
                      <tr>
                        <td class="text-left">Alpha</td>
                        <td class="text-left" />
                        <td class="text-left">
                          {{
                            format_metric_value(
                              portfolio.returns.cumulative_alpha
                            )
                          }}%
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col></v-row
            >
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Chart from "chart.js/auto";
import { currency_format, format_metric_value } from "@/utils/common_utils.js";
export default {
  name: "PortfolioStatusComponent",
  data() {
    return {
      charts: [],
      portfolio_summaries: [],
    };
  },
  mounted() {
    this.get_portfolio_summaries();
  },
  computed: {},
  methods: {
    currency_format,
    format_metric_value,

    get_portfolio_summaries() {
      this.$api
        .getDailyReports()
        .then((resp) => {
          this.portfolio_summaries = resp.data;
          setTimeout(() => this.load_charts(), 100);
        })
        .catch((resp) => {
          console.log("Error getting portfolio summaries:", resp);
        });
    },

    load_charts() {
      // Destroy old charts
      this.charts.forEach((x) => {
        x.destroy();
      });
      this.charts = [];
      this.portfolio_summaries.forEach((x) => {
        // Gen new chart
        let el = document.getElementById("chart1-portfolio" + x.portfolio_id);
        // Make some changes to the default chart config
        x.charts.daily_performance.options.plugins.title = { display: false };
        x.charts.daily_performance.options.scales.x = { display: false };
        x.charts.daily_performance.options.scales.y = { display: false };
        let chart = new Chart(el, x.charts.daily_performance);
        this.charts.push(chart);
      });
    },
  },
};
</script>

<style scoped>
#container {
  padding: 8px;
}

.status-card {
  margin-top: 12px;
  padding: 8px;
  min-height: 320px;
}

h3 {
  margin-bottom: 8px;
}
</style>
