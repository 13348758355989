<template>
  <div id="container">
    <div id="content">
      <br /><br /><br />
      <a href="https://doubleriver.com">
        <v-img
          :src="require('../assets/DoubleRiver.png')"
          class="my-3"
          contain
          height="300"
        />
      </a>
      <p class="text-h2">Double River</p>

      <!-- <p class="text-h5">Employee Access</p> -->
      <br /><br /><br />
      <v-alert type="info" dense text>
        Internal access only. Please
        <a :href="null" @click="loginBtn_click"><u>sign in</u></a> to continue.
      </v-alert>

      <!-- <v-btn
        x-large
        class="white--text"
        @click="loginBtn_click"
        :color="colors.dr_dark_blue"
        >Sign in</v-btn
      > -->
    </div>
  </div>
</template>

<script>
import resources from "@/resources.js";

export default {
  name: "Login",
  methods: {
    loginBtn_click() {
      this.$api.auth.login();
    },
  },
  data() {
    return {
      colors: resources.colors,
    };
  },
};
</script>

<style scoped>
#container {
  display: flex;
  justify-content: center;
}

#content {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
