import AuthService from "./auth_service";

const axios = require("axios");

export default class APIClient {
  auth;
  baseUrl;
  headers;

  constructor() {
    // Ensure Axios sends cookies (CORS)
    axios.defaults.withCredentials = true;

    // If the app is running in 'production' mode (npm build was run)
    // then it'll use Google Secrets and the env. variable to set the base url.
    const env_prod = process.env.NODE_ENV === "production";
    // Otherwise, it'll use /apidev (relies on vue.config.js to properly set up a proxy to a valid server)
    this.baseUrl = env_prod ? process.env.VUE_APP_BACKEND_URL : "/apidev";
    this.auth = new AuthService(this);

    this.headers = {
      "Content-Type": "application/json",
    };

    // Add response time
    axios.interceptors.request.use((x) => {
      x.requestStartedAt = new Date().getTime();
      return x;
    });
    axios.interceptors.response.use((x) => {
      x.responseTime = new Date().getTime() - x.config.requestStartedAt;
      return x;
    });
  }

  // AUTH
  // Helper Functions

  async _get(uri) {
    return await axios({
      method: "GET",
      url: this.baseUrl + uri,
      headers: this.headers,
    });
  }

  async _get_file(uri) {
    return await axios({
      method: "GET",
      url: this.baseUrl + uri,
      headers: this.headers,
      responseType: "blob",
    });
  }

  async _post(uri, data, headers) {
    if (!headers) headers = {};
    return await axios({
      method: "POST",
      url: this.baseUrl + uri,
      headers: { ...this.headers, ...headers },
      data: data,
    });
  }

  async _delete(uri) {
    return await axios({
      method: "DELETE",
      url: this.baseUrl + uri,
      headers: this.headers,
    });
  }

  // AUTH

  get user() {
    return this.auth.authenticatedUser;
  }

  async authGetUser() {
    return this._get("/auth/user");
  }

  async authSignIn(authCode) {
    return this._post("/auth/signin", null, { "Google-Auth-Code": authCode });
  }

  async authSignOut() {
    return this._post("/auth/signout");
  }

  // DATA

  getBenchmarks(assetClass, reportType) {
    let uri = "/data/benchmark-types?";
    if (assetClass) uri += "asset_class=" + assetClass + "&";
    if (reportType) reportType += "report_type=" + reportType;
    return this._get(uri);
  }

  getAssetClasses() {
    return this._get("/data/asset-classes");
  }

  // PORTFOLIOS

  getPortfolios() {
    return this._get("/portfolios");
  }

  getPortfolioDetails(tradingPlatform, portfolioid) {
    return this._get("/portfolios/" + tradingPlatform + "/" + portfolioid);
  }

  // REPORTING

  runPortfolioReport(data) {
    return this._post("/reports/portfolio/run", data);
  }

  runComparisonReport(data) {
    return this._post("/reports/comparison/run", data);
  }

  downloadPdfReport(uuid) {
    return this._get_file("/downloads/" + uuid + "/report/pdf");
  }

  downloadBacktestReport(uuid, benchmark) {
    // TODO: figure out benchmark !
    // let data = { uuid: uuid };
    // if (benchmark != null) data.benchmark = benchmark;
    // return this._post("/backtest/report/generate", data);
    return this._get("/downloads/" + uuid + "/report/json");
  }

  getBacktestQueryParams() {
    return this._get("/storedBacktests/queryParams");
  }

  getBacktestQueryParamCategories(param) {
    return this._get("/storedBacktests/queryParams/" + param + "/categories");
  }

  getBacktestUUIDFromID(id) {
    return this._get("/storedBacktests/" + id + "/uuid");
  }

  isBacktestHidden(id) {
    return this._get("/storedBacktests/" + id + "/isHidden", null);
  }

  hideStoredBacktest(id) {
    return this._post("/storedBacktests/" + id + "/hide", null);
  }

  unhideStoredBacktest(id) {
    return this._post("/storedBacktests/" + id + "/unhide", null);
  }

  requestNewBacktestReport(id) {
    return this._post("/storedBacktests/" + id + "/generateReport", null);
  }

  getPendingBacktestReportsForIds(ids) {
    let uri = "/storedBacktests/pendingReports?";
    if (ids) {
      if (typeof ids != Array) ids = [ids];
      ids = ids.map((x) => "ids=" + x);
      uri += ids.join("&");
    }
    return this._get(uri);
  }

  getAllPendingBacktestReports(include_completed) {
    let uri =
      "/storedBacktests/pendingReports?include_completed=" + include_completed;
    return this._get(uri);
  }

  // SAVED REPORTS

  getDailyReports() {
    return this._get("/daily-reports");
  }

  getPortfolioDailyReport(portfolioId) {
    return this._get("/daily-reports/portfolio/" + portfolioId);
  }

  getSavedReports() {
    return this._get("/saved-reports");
  }

  getSavedReport(savedReportId) {
    return this._get("/saved-reports/" + savedReportId);
  }

  saveReport(data) {
    return this._post("/saved-reports", data);
  }

  deleteSavedReport(id) {
    return this._delete("/saved-reports/" + id);
  }

  // STATS
  getSystemStatus() {
    return this._get("/status/system");
  }

  getInferenceStatus() {
    return this._get("/status/inference");
  }

  // DOCS
  getDocsRepositories() {
    return this._get("/code-docs");
  }

  buildDocsUrl(repository, version, path) {
    let url = this.baseUrl + "/code-docs/" + repository + "/" + version + "/";
    if (path) url += path;
    return url;
  }

  getSavedBacktests(page, pageSize, queryOptions) {
    // testing new API parameter formats
    let requestParams = {
    }

    if (typeof queryOptions.sort[0] !== "undefined") {
        requestParams.sort = queryOptions.sort;
    }
    let url = `/storedBacktests/backtests/query`;
  
    if (pageSize !== -1) {
      url = `${url}?page=${page}&pageSize=${pageSize}`;
    }
    return this._post(url, queryOptions, requestParams);
  }  

  getSavedAlphaSignalsPaginated(page, pageSize, queryOptions) {
    // testing new API parameter formats
    let requestParams = {
    }
    if (typeof queryOptions.sort[0] !== "undefined") {
        requestParams.sort = queryOptions.sort;
    }
    let url = `/storedBacktests/alpha-signals/query`;
    if (pageSize !== -1) {
      url = `${url}?page=${page}&pageSize=${pageSize}`;
    }
    return this._post(url, queryOptions, requestParams);
  }

  getSavedAttribution(page, pageSize, queryOptions) {
    // testing new API parameter formats
    let requestParams = {
    }

    if (typeof queryOptions.sort[0] !== "undefined") {
        requestParams.sort = queryOptions.sort;
    }
    let url = `/storedBacktests/attribution-reports/query`;
    if (pageSize !== -1) {
      url = `${url}?page=${page}&pageSize=${pageSize}`;
    }
    return this._post(url, queryOptions, requestParams);
  }

  getSavedRebalance(page, pageSize, queryOptions) {
    // testing new API parameter formats
    let requestParams = {
    }

    if (typeof queryOptions.sort[0] !== "undefined") {
        requestParams.sort = queryOptions.sort;
    }
    let url = `/storedBacktests/rebalance-reports/query`;
    if (pageSize !== -1) {
      url = `${url}?page=${page}&pageSize=${pageSize}`;
    }
    return this._post(url, queryOptions, requestParams);
  }

  softDeleteAttributionItem(id) {
    return this._post("/storedBacktests/attribution-reports/hide/"+id, null);
  }

  softDeleteAlphaSignalItem(id) {
    return this._post("/storedBacktests/alpha-signals/hide/"+id, null);
  }

  softDeleteBacktestItem(id) {
    return this._post("/storedBacktests/backtests/hide/"+id, null);
  }
}
