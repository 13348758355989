<template>
  <v-container class="text-center">
    <!-- DoubleRiver header with logo -->
    <v-img
      :src="require('../assets/DoubleRiver.png')"
      class="my-3"
      contain
      height="160"
    />
    <br />
    <h1 class="display-2 font-weight-bold mb-2">
      DoubleRiver Investment Platform
    </h1>
    <hr />
    <br />
    <!-- Portfolio Status -->
    <PortfolioStatusComponent />
  </v-container>
</template>

<script>
// @ is an alias to /src
import PortfolioStatusComponent from "@/components/PortfolioStatusComponent.vue";

export default {
  name: "Home",
  components: {
    PortfolioStatusComponent,
  },
};
</script>
