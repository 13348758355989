import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/backtester",
    name: "Backtester",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Backtester.vue"),
  },
  {
    path: "/alpha-signals",
    name: "Alpha Signals",
    component: () => import("../views/AlphaSignals.vue"),
  },
  {
    path: "/rebalance",
    name: "Rebalance",
    component: () => import("../views/Rebalance.vue"),
  },
  {
    path: "/attribution",
    name: "Attribution",
    component: () => import("../views/Attribution.vue"),
  },
  {
    path: "/admiral",
    name: "Admiral",
    component: () => import("../views/Admiral.vue"),
  },
  {
    path: "/paper/stats",
    name: "Paper Trading Statistics",
    component: () => import("../views/Ewok.vue"),
  },
  {
    path: "/portfolios",
    name: "Portfolios",
    component: () => import("../views/Portfolios.vue"),
  },
  {
    path: "/portfolio/:trading_platform/:id",
    name: "Portfolio Detail",
    component: () => import("../views/PortfolioDetail.vue"),
    props: true,
  },
  {
    path: "/portfolioreport",
    name: "Portfolio Report",
    component: () => import("../views/SinglePortfolioReport.vue"),
  },
  {
    path: "/report-status",
    name: "Report Generation Status",
    component: () => import("../views/BacktestReportGenerationStatusPage.vue"),
    props: true,
  },
  {
    path: "/savedreports",
    name: "Saved Reports",
    component: () => import("../views/SavedReports.vue"),
    props: true,
  },
  {
    path: "/status",
    name: "System Status",
    component: () => import("../views/SystemStatus.vue"),
  },
  // Docs
  {
    path: "/docs",
    name: "Docs - Home",
    component: () => import("../views/Docs.vue"),
  },
  {
    path: "/docs/:repository",
    name: "Docs - Repository Home",
    component: () => import("../views/Docs.vue"),
  },
  {
    path: "/docs/:repository/:version",
    name: "Docs - Repository and Version",
    component: () => import("../views/Docs.vue"),
  },
  {
    path: "/docs/:repository/:version/:path",
    name: "Docs - Specific Path",
    component: () => import("../views/Docs.vue"),
  },
  {
    path: "/reportdetail",
    name: "Report Detail",
    component: () => import("../views/ReportDetail.vue"),
    props: true,
  },
  // 404
  {
    path: "*",
    name: "Page Not Found",
    component: () => import("../views/PageNotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
