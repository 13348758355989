//Date Utils
export function is_valid_mysql_date(date) {
  return !isNaN(new Date(date));
}

export function mysql_date(date) {
  return date.toISOString().slice(0, 10);
}

export function parse_mysql_date(str) {
  var parts = str.split("-");
  // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
  // January - 0, February - 1, etc.
  return new Date(parts[0], parts[1] - 1, parts[2]);
}

export function get_relative_date(offset) {
  var today = new Date();
  var res = new Date();
  res.setDate(today.getDate() + offset);
  return res;
}

export function time_string_to_nice_time(time_str) {
  return (
    time_str.slice(5, 7) +
    "/" +
    time_str.slice(8, 10) +
    " " +
    time_str.slice(11, 16)
  );
}

//Object utility
export function copy_object_array(arr) {
  var res = [];
  for (var i = 0; i < arr.length; i++) res.push(Object.assign({}, arr[i]));
  return res;
}

export function dict_to_array(dict) {
  var res = [];
  for (var k in dict) {
    // if (!dict.hasOwnProperty(k)) continue;
    // Made this change as suggested by the linter -John
    if (!(k in dict)) continue;
    res.push(dict[k]);
  }
  return res;
}

export function copy_object_keys(obj, keys) {
  var res = {};
  for (var i = 0; i < keys.length; i++) {
    if (keys[i] in obj) res[keys[i]] = obj[keys[i]];
  }
  return res;
}

export function flip_array(arr) {
  var res = [];
  var keys = Object.keys(arr);
  for (var i = 0; i < keys.length; i++) res[arr[keys[i]]] = keys[i];
  return res;
}

export function data_array_to_objects(arr) {
  if (arr.length < 2) return [];
  var columns = arr[0];
  var res = [];
  for (var i = 1; i < arr.length; i++) {
    var rec = {};
    for (var j = 0; j < arr[i].length; j++) {
      if (j > columns.length) break;
      rec[columns[j]] = arr[i][j];
    }
    res.push(rec);
  }
  return res;
}

//Number formatting

export function format_float_fields(rec, fields, precision) {
  for (var i = 0; i < fields.length; i++) {
    var f = fields[i];
    if (!(f in rec) || !rec[f]) continue;
    rec[f] = (1 * rec[f]).toFixed(precision);
  }
}

export function add_commas_to_number(val) {
  switch (typeof val) {
    case "number":
      return numberFormatter.format(val);
    case "string":
      return +val === +val ? numberFormatter.format(+val) : val;
    default:
      return val;
  }
}

export function currency_format(val) {
  // Create our number formatter.
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(val);
}

export const numberFormatter = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 1,
  maximumFractionDigits: 2,
});
export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export function simpleMetricFormat(value, format) {
  switch (format) {
    case "round":
      return numberFormatter.format(value);
    case "$cents":
      return currencyFormatter.format(value);
    case "$":
      return currencyFormatter.format(value).slice(0, -3);
    case "%":
      return numberFormatter.format(value.toFixed(1)) + "%";
    case "%2":
      return numberFormatter.format(value) + "%";
    case "int":
      return numberFormatter.format(value.toFixed(0));
    default:
      // If format is null, don't format.
      return value;
  }
}

export function format_numbers(arr) {
  var res = copy_object_array(arr);
  for (var i = 0; i < res.length; i++) {
    var obj = res[i];
    for (var field in obj) {
      //   if (!obj.hasOwnProperty(field)) continue;
      // Made this change as suggested by the linter -John
      if (!(field in obj)) continue;
      // git c36083
      // obj[field] = add_commas_to_number(obj[field]);
      let val = obj[field];
      if (typeof val == "string" && +val === +val) {
        val = +val;
      }
      if (typeof val == "number") {
        let size = Math.abs(val);
        let precision = size >= 1000 ? 0 : size >= 100 ? 1 : 2;
        val = val.toFixed(precision);
      }
      obj[field] = add_commas_to_number(val);
    }
  }
  return res;
}

export function numeric_string_sorter(a, b) {
  var x = typeof a == "string" ? +a.replace(/,/g, "") : a;
  var y = typeof b == "string" ? +b.replace(/,/g, "") : b;
  if (!isNaN(x) && !isNaN(y) && typeof x == "number" && typeof y == "number")
    return x - y;
  if (a > b) return 1;
  else if (a < b) return -1;
  else if (a == b) return 0;
  else return a ? 1 : -1;
}

export function capitalize(s) {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function fix_snake_case(s) {
  if (typeof s !== "string") return "";
  var arr = s.split("_");
  var fixed_arr = [];
  for (var i = 0; i < arr.length; i++) {
    fixed_arr.push(capitalize(arr[i]));
  }
  return fixed_arr.join(" ");
}

export function param_is_percentage(param_name) {
  return param_name.includes("%") || param_name.includes("utilization");
}

export function format_metric(name, value) {
  // Check if the value is numeric
  if (!(+value === +value)) return value;
  var precision = 0;
  value = +value;
  if (param_is_percentage(name)) {
    if (Math.abs(value) < 1 && Math.abs(value) > 0) {
      value *= 100;
    }
    precision = 2;
  }
  return add_commas_to_number(value.toFixed(precision));
}

export function format_metric_value(val) {
  // Similar to format_metric but doesn't add the label. Treats all numbers the same,
  // and ignores non-numerical values.
  if (typeof val === "number") return add_commas_to_number(val.toFixed(2));
  else {
    return val;
  }
}

//Financial Math Utils

export function add_returns(ret1, ret2) {
  if (!ret1) ret1 = 0.0;
  if (!ret2) ret2 = 0.0;
  ret1 /= 100.0;
  ret2 /= 100.0;
  return 100 * ((1 + ret1) * (1 + ret2) - 1);
}

export function compute_return_percent(start, stop) {
  return 100 * (stop / start - 1);
}

export function apply_return(start, return_percent) {
  return start * (1 + return_percent / 100.0);
}

//Return the nearest even number of shares, keeping two significant digits
export function compute_shares(price, capital) {
  var true_shares = capital / price;
  var p = Math.floor(Math.log10(true_shares));
  if (p <= 1) return true_shares.toFixed(); //number of shares is < 100
  var div = Math.pow(10, p - 1);
  return div * (true_shares / div).toFixed();
}

//Shared constants

export const x_table_opts = [
  { name: "Mean", type: "mean", title: "Simple average of all tables." },
  { name: "Median", type: "median", title: "Median of all tables." },
  {
    name: "Mean-Median",
    type: "mean_median",
    title: "Average of the mean and median.",
  },
];

//Defaults for parameters that don't require recomputation of scores

export const default_portfolio_params = {
  assets: 50000000,
  portfolio_size: 100,
  max_turnover: 20,
  max_country_share: 30,
  //Buy & Sell Signals
  sell_annualized_return: 20,
  buy_annualized_return: 50,
  max_overnight_return: null,
  stop_loss: null,
  take_profit: null,
  max_day_loss: null,
  //Security-level controls
  accumulate_days: 3, //Max days to buy a stock
  min_weight: 0.5, //Minimum weight a stock should have in the portfolio
  max_weight: 3.0, //Maximum weight that can be allocated to a single security
  turnover_share: 20, //What fraction of a companies daily turnover we are willing to buy (liquidity constraint).
};

export const short_defaults = {
  sell_annualized_return: -30,
  buy_annualized_return: -50,
  assets: 15000000,
};

// Added from report-components.js:
export function safe_obj_get(obj, key, default_value = "") {
  return obj && key in obj ? obj[key] : default_value;
}

export function add_sort_to_headers(headers) {
  for (var i = 0; i < headers.length; i++)
    headers[i]["sort"] = numeric_string_sorter;
  return headers;
}

export function enable_header_filtering(headers) {
  for (var i = 0; i < headers.length; i++) headers[i]["filterable"] = true;
}

//To be searchable, columns need to specifically have filtering enabled.
//This prevents wasteful scouring of the many numeric columns
export function disable_uneeded_filtering(headers) {
  for (var i = 0; i < headers.length; i++) {
    if (!headers[i]["filterable"]) headers[i]["filterable"] = false;
  }
  return headers;
}

export function auto_download_file(
  raw_data,
  filename,
  type = "application/pdf"
) {
  let blob = new Blob([raw_data], { type: type });

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename); //or any other extension
  document.body.appendChild(link);
  link.click();
}

export const company_info_headers = [
  { text: "CIQ ID", value: "ciq_id" },
  { text: "BB Ticker", value: "bb_ticker" },
  { text: "ISIN", value: "isin" },
  { text: "Name", value: "name" },
  { text: "Market", value: "country" },
  { text: "Sector", value: "sector" },
  { text: "Industry", value: "industry" },
];

export function objectTreeFormat(dict) {
  return Object.keys(dict).map((x) => {
    let val = dict[x];
    if (val && typeof val === "object")
      return {
        id: x,
        name: fix_snake_case(x),
        children: objectTreeFormat(val),
      };
    else
      return {
        id: x,
        name: fix_snake_case(x),
        value: val,
        children: [],
      };
  });
}

export function copyClipboard(text) {
  /** Old fashioned JS copy function.
   * May be deprecated at some point. */
  var textArea = document.createElement("textArea");
  textArea.value = text;
  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  document.execCommand("copy");
  textArea.remove();
}
