<template>
  <v-app>
    <v-main>
      <div id="content" v-if="user">
        <DRNav />
        <router-view />
      </div>
      <div v-else>
        <Login />
      </div>
    </v-main>
  </v-app>
</template>

<script>
import Login from "@/components/Login.vue";
import DRNav from "@/components/DRNav.vue";

export default {
  components: {
    Login,
    DRNav,
  },
  data() {
    return {
      user: this.$api.user,
    };
  },
  methods: {},
  mounted() {
    this.$api.auth.addAuthObserver((user) => (this.user = user));
  },
};
</script>
