var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{attrs:{"app":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-img',{staticClass:"shrink mr-2",attrs:{"contain":"","src":require("@/assets/DoubleRiver.png"),"transition":"scale-transition","width":"40"}})],1),_c('div',{attrs:{"id":"nav"}},_vm._l((_vm.nav_routes),function(route){return _c('v-btn',{key:route.name,attrs:{"text":""},on:{"click":function () { return _vm.navigate(route.link); }}},[_vm._v(" "+_vm._s(route.name)+" ")])}),1),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({attrs:{"id":"menu-more"}},'div',attrs,false),on),[_c('v-btn',{attrs:{"text":""}},[_vm._v("Tools"),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)],1)]}}])},[_c('v-list',[_c('v-list-item-group',_vm._l((_vm.menu_options),function(item,i){return _c('v-list-item',{key:i,on:{"click":function($event){return _vm.handle_menu_option(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-"+_vm._s(item.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)}),1)],1)],1),_c('v-menu',{attrs:{"offset-y":"","max-width":"160px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({attrs:{"id":"menu-more"}},'div',attrs,false),on),[_c('v-btn',{attrs:{"text":""}},[_vm._v("Quick Links"),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)],1)]}}])},[_c('v-list',[_c('v-list-item-group',_vm._l((_vm.quick_links),function(item,i){return _c('v-list-item',{key:i},[_c('a',{staticStyle:{"text-decoration":"none","color":"black","text-align":"left","width":"100%"},attrs:{"href":item.link,"target":"_"}},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)])}),1)],1)],1),_c('v-menu',{attrs:{"offset-y":"","max-width":"200px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.user)?_c('v-btn',_vm._g(_vm._b({staticStyle:{"padding-top":"24px","padding-bottom":"24px"},attrs:{"text":"","id":"menu-profile"}},'v-btn',attrs,false),on),[_c('b',[_vm._v(_vm._s(_vm.user.name))]),_c('div',{staticStyle:{"margin-left":"12px"}}),_c('img',{attrs:{"src":_vm.user.picture,"width":"40","height":"40","referrerpolicy":"no-referrer"}})]):_c('span',[_vm._v("Loading profile data... ")])]}}])},[_c('div',{staticStyle:{"background-color":"white"}},[_c('v-btn',{staticStyle:{"width":"100%"},attrs:{"text":""},on:{"click":function($event){return _vm.$api.auth.logout()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-logout")]),_vm._v("Sign Out")],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }