document.title = "Investment Platform";

import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import APIClient from "./utils/api_client";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { createPinia, PiniaVuePlugin } from "pinia";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";

library.add(faSortDown, faSortUp);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(Antd);
Vue.use(PiniaVuePlugin);
const pinia = createPinia();
Vue.config.productionTip = false;
Vue.prototype.$api = new APIClient();

new Vue({
  router,
  vuetify,
  pinia,
  render: (h) => h(App),
}).$mount("#app");
